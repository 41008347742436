.gdpr-notification {
  width: 100%;
  position: absolute;
  bottom: calc(100% - 18px);
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  background: transparent;
  z-index: 10;

  &__icon-close {
    width: 22px;
    height: 22px;
    position: absolute;
    right: 10px;
    cursor: pointer;

    svg {
      width: 22px;
      height: 22px;
    }

    * {
      cursor: pointer;
    }
  }
}

.success {
  background: #cceec9;
  color: #6d9643;
  font-weight: bold;

  .gdpr-notification__icon-close {
    > i svg circle {
      fill: #72c472 !important;
    }
  }
}

.error {
  background: #f9e2e3;
  color: #ff0000;
  font-weight: bold;

  &__icon-close {
    > i svg circle {
      fill: #f8c1c1 !important;
    }
  }
}
