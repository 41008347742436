.gdpr-card {
  margin: 0.75rem;
  color: #4a4a4a;
  max-width: 100%;
  position: relative;

  &__header {
    align-items: stretch;
    display: flex;
    align-items: center;
    padding: 13px 0;

    &-title {
      padding: 0;
      display: flex;
      flex-grow: 1;
      font-size: 24px;
      font-weight: bold;
    }
  }

  &__body {
    background-color: transparent;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.18);
    background: #fff;
  }
}
