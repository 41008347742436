.simple-loader {
  margin: 10px auto;
  margin-bottom: 20px;

  h1 {
    font-size: 20px;
    text-align: center;
  }

  span {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    left: 50%;
    margin-left: -10px;
    animation: 3s infinite linear;

    &:nth-child(2) {
      background: #FC3C63;
      animation: kiri 1.2s infinite linear;
    }
    &:nth-child(3) {
      background: #FEDD62;
      z-index: 100;
    }
    &:nth-child(4) {
      background: #00d1b2;
      animation: kanan 1.2s infinite linear;
    }
  }
}

@keyframes kanan {
  0% { transform: translateX(20px); }
  50% { transform: translateX(-20px); }
  100% {
    transform: translateX(20px);
    z-index: 200;
  }
}

@keyframes kiri {
  0% {
    transform: translateX(-20px);
    z-index: 200;
  }
  50% { transform: translateX(20px); }
  100% { transform: translateX(-20px); }
}
