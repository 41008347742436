.add-auth-button {
    margin: 10px;
}

.authorizations-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
