@import '../../styles/theme.scss';

.feature-flag {
  margin: 0px 40px;
    background: $white;
    border: 1px solid $very-light-gray;
    box-sizing: border-box;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
    padding: 10px 5px 10px 30px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;

  &__title {
    font-size: 22px;
    line-height: 1.2;
    color: $black;
  }
  &__title:hover {
    cursor: pointer;
  }

  &__body {
 width: 100%;
    &__input {
      position: relative;
      display: flex;
      flex-direction: column;

       input {
       height: 34px;
      color: $gray;
      font-size: 14px;
      line-height: 1.2;
      border: 1px solid $silver;
      border-radius: 4px;
  
      }
       input:hover {
        border: 1px solid $turquoise-blue;
        box-shadow: 0px 0px 9px $turquoise-blue;
        cursor: pointer;
       }

       textarea {
         resize: none;
        padding-right: 7px;
         height: auto;
         width: 100%;
         font-size: 14px;
         line-height: 1.7;
         color: $gray;
         padding-right: 7px;
         overflow: auto;
         border: transparent;
         outline: none;
       }

       textarea:hover {
       cursor: pointer;
      }

       textarea::-webkit-scrollbar {
        width: 24px;
    }
    textarea::-webkit-scrollbar-track {
      border-left: 1px solid $grey;
      border-right: 1px solid $grey;
     
    }

    
    textarea::-webkit-scrollbar-thumb {
       background: #C4C4C4;
       -webkit-box-shadow:inset 0px 0px 0px 3px $white;
       -moz-box-shadow:inset 0px 0px 0px 3px $white;
       box-shadow:inset 0px 0px 0px 3px $white;
       border-left: 1px solid #C4C4C4;
       border-right: 1px solid #C4C4C4;
    }
    
    textarea::-webkit-scrollbar-button:end:increment,
    textarea::-webkit-scrollbar-button:start:decrement {
      display: block;
      height: 24px;
      width: 24px;
      border: 1px solid $grey;
}
       
  textarea::-webkit-scrollbar-button:vertical:start:decrement {
    background:
        linear-gradient(120deg, $white 40%, rgba(0, 0, 0, 0) 41%),
        linear-gradient(240deg, $white 40%, rgba(0, 0, 0, 0) 41%),
        linear-gradient(0deg, $white 30%, rgba(0, 0, 0, 0) 31%);
    background-color: $black;
}

  textarea::-webkit-scrollbar-button:vertical:end:increment {
    background:
        linear-gradient(300deg,$white 40%, rgba(0, 0, 0, 0) 41%),
        linear-gradient(60deg,$white 40%, rgba(0, 0, 0, 0) 41%),
        linear-gradient(180deg,$white 30%, rgba(0, 0, 0, 0) 31%);
    background-color: $black;
}

  textarea::-webkit-scrollbar-button:horizontal:end:increment {
    background:
        linear-gradient(210deg,$white 40%, rgba(0, 0, 0, 0) 41%),
        linear-gradient(330deg,$white 40%, rgba(0, 0, 0, 0) 41%),
        linear-gradient(90deg,$white 30%, rgba(0, 0, 0, 0) 31%);
    background-color: $black;
}

   textarea::-webkit-scrollbar-button:horizontal:start:decrement {
    background:
        linear-gradient(30deg,$white 40%, rgba(0, 0, 0, 0) 41%),
        linear-gradient(150deg,$white 40%, rgba(0, 0, 0, 0) 41%),
        linear-gradient(270deg,$white 30%, rgba(0, 0, 0, 0) 31%);
    background-color: $black;
}

    }
    &__input:first-child {
      flex-grow: 1;
      > input {
       width: 40px;
       text-align: center;
      }
    }
    
    &__input:first-child .error {
      border: 1px solid $indian-red;
      box-shadow: 0px 0px 4px $indian-red;
    }

    &__input:last-child {
      flex-grow: 3;
    }
    &__input:nth-child(2) {
      flex-grow: 2;
      > input {
        max-width: 198px;
        width: 100%;
        padding-left: 12px;
      }
    }
    
}

.button {
     display: block;
      width: 40px;
      height: 40px;
      border: 1px solid $turquoise-blue;
      background: $white;
      border-radius: 4px;
      margin-left: auto;
      cursor: pointer;

      > i {
        > svg {
          height: 1.5rem;
          fill: $turquoise-blue;
        }
      }
}

.button:hover {
  background: $turquoise-blue;

  > i {
    > svg {
      fill: $white;
    }
  }
}

.button:disabled {
  border: 1px solid $light-gray;

  > i {
    > svg {
      fill: $light-gray;
    }
  }
}

.button:disabled:hover {
  background: unset;
}

&__title__container {
  display: flex;
  align-items: center;
}

&__body__container {
  display: flex;
  flex-grow: 2;
}

&__body__wrapper {
  display: flex;
  flex-direction: row-reverse;
    align-items: center;
    margin-top: 9px;
    padding-top: 9px;
    border-top: 1px solid $very-light-gray;
}

&__body__editBtn {
  align-self: end;
    padding-bottom: 10px;
    border-bottom: 1px solid $very-light-gray;
}
&__input__span {
  font-size: 14px;
  line-height: 1.2;
  color: $black;
  font-weight: $bold;
  text-transform: uppercase;
  padding: 14px 0px 20px;
  margin-bottom: 9px;
  border-bottom: 1px solid $very-light-gray;
}

&__input__percent {
  position: absolute;
  bottom: 71%;
  left: 50px;
  color: $gray;
}
 
&__text-container {
  padding: 7px;
  border: 1px solid $silver;
  border-radius: 4px;
}
&__text-container:hover {
  border: 1px solid $turquoise-blue;
  box-shadow: 0px 0px 9px $turquoise-blue;
}

&__text-container.disabled {
  pointer-events: none;
}

&__disabled {
  pointer-events: none;
  background: $white;
}

}

