@import '../../styles/theme.scss';


.rollout-page {
    font-family: $primary-font;   
 &__rollout-header {
        padding: 60px 40px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
   
    span {
    font-size: 32px;
    font-weight: $bold;
    line-height: 1.2;
    color: $black;
   } 

   .input-container {
    position: relative;
    max-width: 490px;
    height: 40px;
    width: 100%;
   }

   input {
    max-width: 490px;
    width: 100%;
    height: 40px;
    padding-left: 22px;
    font-size: 18px;
    line-height: 22px;  
    border: 1px solid $silver-light;
    border-radius: 4px;
   }

   .close-button {
    position: absolute;
    right: 0;
    padding-right: 10px;

    > i {
      > svg {
        height: 1.5rem;
      }
    }

   }

   button {
    width: 180px;
    height: 40px; 
    font-family: $primary-font; 
    color: $white;
    font-size: 18px;
    line-height: 1.2;
    border: transparent;
    background: $turquoise-blue;
    border-radius: 2px;
  }
}
.rollout-modal {
   
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: $shadow, $alpha: 0.5);
    transition: opacity .2s ease;
    z-index: 40;
&__content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 700px;
    width: 100%;
    padding: 30px;
    background-color: $white;
  }

&__form__header {
   display: flex;
   align-items: center;
   margin-bottom: 30px;  
span {
    font-weight: $bold;
    font-size: 28px;
    line-height: 1.2;
    color: $black;
   }
div  {
    margin-left: auto;
   }

 }

&__form {
    label {
        display: flex;
        font-size: 20px;
        line-height: 1.6;
        color: $black;
        margin-bottom: 30px;

    }
   
    input {
        height: 40px;
        font-size: 22px;
        background: $white;
        padding-left: 10px;
        border: 1px solid $silver;
        border-radius: 4px;
    }
    input[type="text"] {
        max-width: 474px;
        width: 100%;
        margin-left: auto;
    }

input[name="percentage"] {
    max-width: 120px;
    width: 100%;
    margin-left: 68px;
    -webkit-appearance: none;
    /* Firefox */
    -moz-appearance: textfield;
  }
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
      margin: 0;
  }

.form__input.error {
border: 1px solid $indian-red;
box-shadow: 0px 0px 4px $indian-red;
    }
    input[name="group"] {
        max-width: 299px;
        width: 100%;
        margin-left: 112px;
    }
  }
&__form__symbol {
    display: inline-flex;
    align-items: center;
    color: $gray;
    margin-left: 10px;
  }

&__form__footer {
    text-align: right;
  }

button {
   min-width: 130px;
   padding: 10px 0px;
   color: $turquoise-blue;
   font-size: 20px;
   background: $white;
   border: 1px solid $turquoise-blue;
   border-radius: 4px;
  }

button[type="submit"] {
   background: $turquoise-blue;
   color: $white;
   margin-right: 10px;
  }

button[type="submit"]:disabled {
    color: $turquoise-blue;
    background: $white;
  }

}


}

