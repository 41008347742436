.spinner-container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: center;
  align-self: center;
  margin-top: 5em;
  transform: translate3d(0, 0, 0);
  z-index: 99;
  left: calc(50% + 5em);

  svg.spinner {
    width: 10em;
    height: 10em;
    animation: rotator 1.4s linear infinite;
    .path {
      stroke-dasharray: 187;
      stroke-dashoffset: 0;
      transform-origin: center 50%;
      animation: dash 1.4s ease-in-out infinite, colors 5.6s ease-in-out infinite;
    }
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: 187;
  }

  50% {
    stroke-dashoffset: 46.75;
    transform: rotate(135deg);
  }

  100% {
    stroke-dashoffset: 187;
    transform: rotate(450deg);
  }
}

@keyframes colors {
  0% {
    stroke: #e15e6e;
  }

  25% {
    stroke: #fbe6e8;
  }

  50% {
    stroke: #e15e6e;
  }

  75% {
    stroke: #fbe6e8;
  }

  100% {
    stroke: #e15e6e;
  }
}
