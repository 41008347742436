.gdpr-page {
  margin: 0 auto;
  position: relative;

  input {
    outline: 0;
  }

  &__info {
    display: flex;
    justify-content: flex-end;
    margin: 0 0.75rem;
    font-size: 14px;
    color: #b0b0b0;
    font-style: italic;
  }
}
