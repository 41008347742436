.token-input {
  display: inline-flex;
}

.token-input-list {
  display: none;
  position: absolute;
  z-index: 1;
  border: 1px solid #aaa;
  background: #fff;
  top: 100%;
  left: 0;
  padding: 5px 0px;
  max-height: 400px;
  overflow: auto;
  width: 100%;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

.token-input.is-open .token-input-list {
  display: block;
}

.token-input-input {
  border: 0;
  box-sizing: border-box;
  font-size: 1rem;
  min-height: 2em;
  outline: 0;
  width: 100%;
}

.token-label {
  line-height: 20px;
}

.token-input-button {
  cursor: default;
  font-size: 20px;
  position: absolute;
  top: 2px;
  right: 6px;

  &:active {
    color: #4095BF;
  }
}

.token-input-option {
  display: block;
  padding: 2px 16px;
  cursor: default;

  &:focus {
    outline: 0;
    color: white;
    background: hsl(200, 50%, 50%);
  }
}

.token-input-selected:before {
  content: '✓';
  position: absolute;
  left: 4px;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

ul.tokens {
  display: flex;
  z-index: 1;
  list-style: none;
  margin: 0;
  padding: 4px 6px;
  border: 1px solid #A6A6A6;
  background-color: white;
  border-radius: 3px;
  cursor: text;
  position: relative;
  will-change: transform;
  flex-wrap: wrap;

  li {
    margin-top: inherit;
  }
}

.token {
  background-color: #F5F7F8;
  border: 1px solid #C1C7CF;
  color: #7C8E9A;
  border-radius: 3px;
  padding: 3px 8px;
  margin-right: 6px;
  margin-top: 2px;
  margin-bottom: 2px;
}

.token-delete-button {
  margin-left: 5px;
  cursor: pointer;

  &:hover {
    color: red;
  }
}

.token-input-loading {
  display: flex;
}
