@import '../../../styles/theme.scss';

.gdpr-status-list {
  width: 100%;
  padding: 1.5rem;

  &__message {
    text-align: center;
    font-size: 18px;
    font-style: italic;
  }

  &__table {
    width: 100%;

    td {
      word-break: initial;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      padding: 18px 5px 0;
      max-width: 200px;
      color: #303030;
    }

    &-head {
      border-bottom: 1px solid #ececec;
      font-weight: bold;

      &-row-actions > div {
        justify-content: center;
      }

      td {
        padding: 0 5px 10px 5px;
        color: #1e1e1e;

        .gdpr-status-list__table-head-column {
          display: flex;
          align-items: center;

          > span {
            margin-right: 5px;
          }

          &-icons-block {
            display: flex;
            flex-direction: column;
            cursor: pointer;

            * {
              cursor: pointer;
            }

            .gdpr-status-list__arrow-up {
              transform: rotate(180deg);
              margin: 2px 0 -1px;
            }

            > i {
              height: 7px;
              width: 7px;

              > svg {
                height: 7px;
                width: 7px;

                g {
                  fill: #aaaaaa;
                }
              }
            }
          }
        }
      }
    }
  }

  &__actions-column {
    justify-content: center;
  }

  &__table-actions-container {
    width: 100%;
    display: flex;
    justify-content: space-around;

    &-spinner {
      width: 20px;
      height: 20px;
    }

    > button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 100%;
      border: 1px solid;
      width: 60px;
      border-radius: 8px;
      cursor: pointer;
      width: 25%;

      * {
        cursor: pointer;
      }

      &:hover {
        background: #f4f5f7;
      }

      > i svg {
        width: 20px;
        height: 20px;
      }
    }

    button:disabled {
      cursor: not-allowed;
      background: #f5eded;
      opacity: 0.4;

      * {
        cursor: not-allowed;
      }
    }
  }

  &__table-status-container {
    display: flex;
    justify-content: flex-start;

    > i {
      height: 24px;
      width: 24px;
      align-items: center;
      margin: 0 5px 0 0;
      padding-bottom: 2px;

      svg {
        height: 24px;
        width: 24px;
      }
    }
  }

  div.gdpr-status-list__disabled-option {
    opacity: 0.3;
    cursor: default;

    * {
      cursor: default;
    }

    &:hover {
      background: inherit;
      color: inherit;

      > i svg path {
        fill: inherit;
      }
    }
  }
}

.completed {
  color: #6fb922;
}

.failed {
  color: #ec2727;
}

.with-blur {
  filter: blur(2px);
}

@media #{$mobile-media} {
  .gdpr-status-list {
    &__table {
      td {
        padding: 18px 2px 0;
      }
      &-head {
        td {
          padding: 0 0 10px 0;
        }
        &-row {
          &-date,
          &-activity,
          &-group,
          &-id,
          &-creator {
            width: 0;
            position: absolute;
            opacity: 0;
          }
        }
      }
    }
  }
}

@media #{$large-media} {
  .gdpr-status-list {
    &__table {
      td {
        padding: 18px 5px 0;
        max-width: 100px;
      }
      &-head {
        td {
          padding: 0 0 10px 0;
        }
        &-row {
          &-group,
          &-id,
          &-creator {
            width: 0;
            position: absolute;
            opacity: 0;
          }
        }
      }
    }
  }
}
