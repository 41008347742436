@import '../../../styles/theme.scss';

.gdpr-search-form {
  &__card {
    padding: 1.4rem 1.5rem 0.6rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &-field {
      justify-content: space-between;
      display: flex;
      align-items: center;
      border: 1px solid #cdcdcd;
      border-radius: 4px;
      height: 40px;
      padding: 0 5px;

      &-input {
        border: 0;
        width: 70%;
        height: 90%;
        font-size: 16px;
      }

      &-input::placeholder {
        font-style: italic;
        color: #b2b2b2;
      }

      &-dropdown {
        display: flex;
        justify-content: space-between;
        padding: 0 3px;
        font-size: 12px;
        align-items: center;
        cursor: pointer;
        border-left: 1px solid #979797;

        > i {
          height: 9px;
          width: 9px;

          svg {
            height: 9px;
            width: 9px;
          }
        }
      }

      &-error {
        border: 1px solid #ec2727;
        position: relative;

        > span {
          color: #ec2727;
          position: absolute;
          top: 38px;
        }
      }
    }

    &-field-short {
      width: 13%;
    }

    &-field-medium {
      width: 28%;
      margin-top: 20px;

      > input {
        width: 100%;
      }
    }

    &-field-long {
      width: 80%;
    }
    &-field-descr {
      width: 70%;
      margin-top: 20px;
    }

    &-disabled-field {
      background: #f2f2f2;
      cursor: default;

      > input {
        background: #f2f2f2;
      }

      * {
        cursor: default;
      }
    }

    &-process-dropdown {
      border: 1px solid #cdcdcd;
      border-radius: 4px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      padding: 0 5px;

      > div {
        border: 0;
        width: 100%;
      }

      > i {
        height: 9px;
        width: 9px;

        svg {
          height: 9px;
          width: 9px;
        }
      }
    }

    > button {
      border-radius: 4px;
      width: 13%;
      height: 40px;
      text-align: center;
      font-size: 14px;
      font-weight: bold;
      margin-top: 20px;
      margin-left: auto;
    }

    &-submit-btn {
      cursor: pointer;
      border: 1px solid transparent;
      background: #4d91cd;
      color: #fff;

      &:disabled {
        border: 1px solid #d2d2d2;
        background: #d2d2d2;
        color: #fff;
      }
    }
  }

  &__dropdown-container {
    border: 1px solid #cdcdcd;
    min-width: 120px;
    margin-top: 10px;

    &-list-item {
      padding: 10px;
      cursor: pointer;
      font-size: 12px;

      &:hover {
        background: #478fcc;
        color: #fff;
      }
    }
  }
 
 > span {
  position: absolute;
  top: 48%;
  padding-left: 1.8rem;
  }
}

.disabled-option {
  opacity: 0.4;
  cursor: default;

  &:hover {
    background: #fff;
    color: #585858;
  }
}

@media #{$mobile-media} {
  .gdpr-search-form {
    &__card {
      flex-direction: column;

      &-field {
        width: 100%;
        margin: 5px 0;
      }

      > button {
        width: 100%;
        height: 40px;
      }
    }
  }
}

@media #{$tablet-media} {
  .gdpr-search-form {
    &__card-field-input {
      width: calc(100% - 80px);
    }
    &__card-field-dropdown {
      width: 74px;
    }
    &__dropdown-container,
    &__card-process-dropdown {
      width: 18%;
    }
  }
}

@media #{$med-media} {
  .gdpr-search-form {
    &__card-field-input {
      width: calc(100% - 150px);
    }
    &__card-field-dropdown {
      width: 144px;
    }
    &__dropdown-container,
    &__card-process-dropdown {
      width: 18%;
    }
  }
}
