.tdp-date-picker {
  display: inline-block;
  position: relative;

  button {
    border: none;
    background: transparent;
    box-shadow: none;
    padding: 0;
    margin: 0;
    height: auto;
    &:hover {
      background: transparent;
      box-shadow: none;
    }
    &:focus {
      box-shadow: none;
    }
  }

  .tdp-calendar-container {
    position: absolute;
    top: 38px;
    right: 100%;
    padding: 6px 0;
    z-index: 9999;
    border-radius: 6px;
    box-shadow: 0 24px 50px 0 rgba(0, 0, 0, 0.12), 0 5px 15px rgba(0, 0, 0, 0.07);

    .tdp-calendar {
      color: #fff;
      background: #fff;
      width: 230px;

      td, th {
        text-align: center;
      }
      thead {
        th {
          padding: 10px 0;
        }
      }
    }
  }


  .tdp-calendar-current-month {}

  .tdp-calendar-prev-month,
  .tdp-calendar-next-month {
    cursor: pointer;

    &:hover {
      background: rgba(192, 232, 250, 0.5);
    }
  }

  .tdp-calendar-day {
    font-size: 13px;
    cursor: pointer;
    padding: 11px 12px 9px;

    &:hover {
      background: rgba(192, 232, 250, 0.5);
    }
  }

  .tdp-calendar-day-active {
    background: rgba(192, 232, 250, 0.5);
  }

  .tdp-calendar-day-disabled {
    opacity: 0.3;
  }

  .tdp-calendar-day-other-month {
    color: #ddd;
  }

  .tdp-calendar-day-names {
    font-size: 11px;
    background: #fff;

    td {
      padding: 7px 12px 6px;
    }
  }

  .tdp-input {
    position: relative;
    width: 100px;
    margin: 5px;
    padding: 5px 0;
    border: 1px solid #d1d1d1;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    border-radius: 4px;
    background-color: #f0f0f0;
    cursor: pointer;
    color: transparent;
    text-shadow: 0 0 0 #666;
    outline: none;
  }
}
