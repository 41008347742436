@import './theme.scss';

$guide-line-color: $smoke;
$text: #9A9A9A;

@mixin graph-colors($colors...) {
  .series, .y-axis-reference-lines, .key {
    @for $i from 1 through length($colors) {
      .series-#{$i - 1}-color {
        background: nth($colors, $i);
      }

      path.series-#{$i - 1}-color {
        stroke: nth($colors, $i);
      }

      rect.series-#{$i - 1}-color {
        fill: nth($colors, $i);

        &.extraseries-color{
          fill: $smoke;
        }

        &.inverted {
          stroke: nth($colors, $i);
          fill: transparent;
        }
      }

      .bar.series-#{$i - 1}-color {
        border-color: nth($colors, $i);
        background: transparent;
      }
    }
  }
}

.graph-wrapper {
  .tooltip {
    position: absolute;
    z-index: 2;
    left: 0;
    top: 0;
  }
}

.graph {
  position: relative;
  margin-bottom: 36px;

  .fetch-error, .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: -1px;
    height: 254px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .refresh-icon {
      margin-bottom: 15px;
    }

    h2 {
      font-size: 1rem;
    }

    a {
      cursor: pointer;
      color: $royal;

      &:focus {
        border: none;
        outline: unset;
      }
    }

    .not-integrated {
      height: 64%;
      margin-bottom: 17px;
      padding: 0 15%;
      text-align: center;
      background: white;
      z-index: 1;
      margin-top: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .pulse {
        margin: 0 auto;
        width: 24px;
        height: 24px;
        border-radius: 12px;
        text-align: center;
        color: white;
        font-family: 'Lato', sans-serif;
        font-size: 24px;
        animation: 8s linear infinite pulse;

        @keyframes pulse {
          0% { background: rgba($royal, 1.0) }
          5% { background: rgba($royal, 0.5) }
          10% { background: rgba($royal, 1.0) }
          15% { background: rgba($royal, 0.5) }
          20% { background: rgba($royal, 1.0) }
          25% { background: rgba($royal, 0.5) }
          30% { background: rgba($royal, 1.0) }
          100% { background: rgba($royal, 1.0) }
        }

        span {
          display: block;
          padding-top: 2px;
          padding-left: 0;
        }
      }

      a {
        text-decoration: none;
      }

      h2 {
        margin: 10px auto 15px;
      }
    }
  }

  .loading-dots {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba($snow, 0.5);
    z-index: 2;
  }

  h3 {
    font-size: 0.875rem;
    font-weight: 400;
    margin: 0;
    margin-bottom: 6px;
    line-height: 19.6px;
    min-height: 19px;
  }

  h4 {
    line-height: 1em;
    color: $smoke;
    font-size: 0.875rem;
    margin: 0;
    font-weight: normal;
    margin-bottom: 13px;

    &:empty {
      margin-bottom: 0;
    }
  }

  svg {
    font-weight: normal;
    overflow: visible;
  }

  .axis {
    text {
      stroke: none;
      fill: $text;
    }
  }
  .y.axis {
    .domain {
      fill: none;
      stroke: none;
    }

    .tick {
      line {
        stroke-width: 1px;
        stroke: $guide-line-color;
        stroke-dasharray: 5;
        opacity: 0.5;
        &.origin {
          stroke-dasharray: none;
        }
      }

      text {
        &.origin {
          display: none;
        }
      }
    }
  }
  .x.axis {
    .domain {
      fill: none;
      stroke: none;
    }
    .tick {
      line {
        // stroke: none;
        stroke: $guide-line-color;
      }
      text {
        font-weight: 500;
        text-transform: uppercase;
      }
    }
  }
  .series {
    path {
      stroke-width: 3px;
      stroke-linecap: round;
    }

    rect {
      &.allversions-color {
        fill: $shadow;
        &.android {
          fill: $shadow;
        }
      }

      &.currentversion-color {
        fill: $sky;
      }

      &.extraseries-color {
        fill: $smoke;
      }
    }
  }
  .callouts {
    .callout {
      line {
        stroke: $smoke;
        stroke-width: 2;
        stroke-dasharray: 1, 3;
        stroke-linecap: round;
      }
      text {
        font-size: 0.7rem;
        font-weight: bold;
        fill: $shadow;
      }
    }
  }

  .x-axis-reference-lines {
    text {
      font-size: 9px;
      font-weight: 500;
      font-family: $primary-font;
      writing-mode: vertical-lr;
      writing-mode: tb;
    }
    path {
      stroke: $smoke;
      stroke-width: 1px;
    }
  }
  .recticles {
    line {
      stroke: lighten($scorpion, 50%);
    }
    circle {
      stroke: rgba($scorpion, 0.25);
      fill: rgba($white, 0.25);
    }
  }

  .key {
    display: flex;
    justify-content: flex-start;
    color: $shadow;
    margin-bottom: 13px;
    min-height: 1em;

    .label {
      text-transform: uppercase;
      line-height: 14px;
      font-size: 0.7rem;
      font-weight: 300;
      margin-right: 20px;

      .key-color{
        border-radius: 100%;
        content: ' ';
        display: inline-block;
        height: 7px;
        margin-right: 10px;
        width: 7px;

        &.current {
          background: $sky;
        }
        &.alltime {
          background: $shadow;
          &.android {
            background: $shadow;
          }
        }
        &.beforeapptentive {
          background: $smoke;
        }
      }
    }
  }

  .summary {
    text-align: right;
    font-size: 10px;
    font-weight: 400;
    color: $smoke;
    min-height: 19px;
  }

  .text-container {
    display: none;
    background: lighten($scorpion, 80%);
    opacity: 0.9;
    border: solid lighten($scorpion, 50%) 1px;
    padding: 10px;
    box-shadow: 0px 0px 5px $onyx;
    border-radius: 5px;
    .x {
      font-size: 1rem;
      font-weight: bolder;
      margin-bottom: 10px;
    }
    .ys {
      display: flex;

      .y {
        margin-right: 20px;
      }
    }
    .label {
      font-weight: normal;
      font-size: 0.7rem;
    }
    .value {
      font-size: 1.5em;
    }

    &.left-arrow {
      &:before {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        margin-top: -12px;
        left: -24px;
        top: 50%;
        border: 10px solid transparent;
        border-right: 15px solid rgba(193,193,193,0.5);
        z-index: 3;
      }
      &:after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        margin-top: -10px;
        left: -18px;
        top: 50%;
        border: 8px solid transparent;
        border-right: 12px solid white;
        z-index: 3;
      }
    }
    &.right-arrow {
      &:before {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        margin-top: -12px;
        right: -24px;
        top: 50%;
        border: 10px solid transparent;
        border-left: 15px solid rgba(193,193,193,0.5);
        z-index: 3;
      }
      &:after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        margin-top: -10px;
        right: -18px;
        top: 50%;
        border: 8px solid transparent;
        border-left: 12px solid white;
        z-index: 3;
      }
    }
  }

  // Default colors
  @include graph-colors($pine, $mint, $mango, $coral, $chinook);
  .hover-info {
    @include hover-info-colors($pine, $mint, $mango, $coral, $chinook);
  }
}

.histogram-timeseries-graph {
  .x.axis {
    .tick {
      text {
        font-size: 0.7rem;
        visibility: hidden;
        &.display {
          visibility: visible;
        }
      }
    }
  }
}

.ratings-distribution {
  @include graph-colors($forest, $pine, $mint);
  .x.axis {
    .tick {
      line {
        display: none;
      }
    }
  }
  .overlay {
    .not-integrated {
      padding-top: 8%;
    }
  }
}

.apple-ratings-dialog-bar {
  @include graph-colors($lapis, $silver, $sky);
  .x.axis {
    .tick {
      line {
        display: none;
      }
    }
  }
  .tooltip {
    .hover-info {
      h3 {
        display: block;
      }
      .series-summaries {
        .series-summary:nth-child(1):nth-last-child(2) {
          h5 {
            color: $shadow;
          }
        }
        .series-summary:nth-child(2):nth-last-child(1) {
          h5 {
            color: $smoke;
          }
        }
      }
    }
  }
}

.love-ratio-aggregation.simple-callout  {
  background-color: $snow;
  .main-callout {
    .main-callout-title {
      h1 {
        color: $eggplant;
      }
      p {
        &:first-of-type {
          color: $eggplant;
          font-weight: 400;
        }
      }
    }
    .callout-row-1 {
      padding: 0 15px 0 15px;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: distribute;
      justify-content: space-around;
      margin-top: 15px;

      .callout-grouping-1,
      .callout-grouping-2 {
        max-width: 50%;
      }
    }
  }
}

.ratings-dialog-count.simple-callout {
  margin-top: 35px;
  height: auto !important;
  .difference-integer {
    font-size: 18px;
  }
}

.apple-ratings-dialog-count.simple-callout {
  background-color: $snow;
  .main-callout {
    p {
      margin-top: 10px;
      margin: 0;
    }
    .callout-row-1 {
      padding: 0 15px 0 15px;
      display: flex;
      justify-content: space-around;
      margin-top: 15px;

      .callout-grouping-1,
      .callout-grouping-2 {
        max-width: 50%;
      }
      .callout-grouping-1 {
        color: $lapis;
      }
      .callout-grouping-2 {
        color: $forest;
      }
    }
  }
}

.web-count.simple-callout {
  background-color: $snow;
  .main-callout {
    p {
      margin-top: 10px;
      margin: 0;
    }
    .callout-row-1 {
      padding: 0 15px 0 15px;
      display: flex;
      justify-content: space-around;
      margin-top: 15px;

      .callout-grouping-1,
      .callout-grouping-2 {
        max-width: 50%;
      }
    }
  }
}

.simple-callout {
  .datum-container {
    display: flex;
    align-items: baseline;
    justify-content: center;
    h5 {
      margin: 0 0 0 3px;
    }
  }
}

.horizontal-bar-graph {
  h4 {
    height: 14px;
    margin-bottom: 13px;
    &:empty {
      margin-bottom: 13px;
    }
  }
  .table-container {
    height: 175px;
    border-bottom: solid 1px $nimbus;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 10px;
    max-height: 175px;

    thead {
      text-align: right;
      font-size: 10px;
      font-weight: 400;
      border-bottom: solid 1px $nimbus;
      color: $smoke;

      th {
        margin: 0;
        padding: 0;
        line-height: 16px;
      }
    }

    .column {
      width: 5em;
      text-align: right;
      padding: 0 10px;
    }
    td {
      font-weight: normal;
      position: relative;
      padding: 2px 10px;
      font-size: 0.8rem;
      line-height: 20px;
      z-index: 1;
      height: 32px;
      &.column {
        color: $shadow;
      }
      &.bar {
        font-weight: 400px;
        height: 28px;
        z-index: 1;

        .label {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 10px;
          right: 0;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          text-align: left;
          vertical-align: middle;
          font-weight: bold;
          z-index: 2;
        }
      }
      .bar {
        display: block;
        position: absolute;
        border-width: 1px;
        border-style: solid;
        border-radius: 5px;
        top: 2px;
        bottom: 2px;
        left: 0;
        min-width: 3px;
        z-index: 1;
      }
    }
  }
}

.ratings-over-time-graph {
  .hover-info {
    h3 {
      display: block;
    }
  }
  // @include graph-colors($chinook, $coral, $mango, $mint, $pine);
  .week-hover-title-qualifier {
    font-weight: $regular;
  }
}

.message-center-graph {
  @include graph-colors($crimson);
  .hover-info {
    @include hover-info-colors($crimson);
  }
}
.surveys-graph {
  @include graph-colors($mandy);
  .hover-info {
    @include hover-info-colors($mandy);
  }
}
.interactions-graph {
  @include graph-colors($coral, $mango);
  .hover-info {
    @include hover-info-colors($coral, $mango);
  }
}

.love-ratio-over-time-graph {
  @include graph-colors($eggplant, $nimbus);
  .hover-info {
    @include hover-info-colors($eggplant, $nimbus);
  }
}

.web-notes-graph {
  @include graph-colors($silver, $crimson);
  .hover-info {
    @include hover-info-colors($silver, $crimson);
  }
}

.ratings-dialog-horizontal-bar {
  @include graph-colors($royal, $sky, $amethyst);
  .table-container {
    table {
      tr:nth-child(4) {
        visibility: hidden;
        td {
          height: 28px;
        }
      }
      tr:last-child {
        td.bar {
          span.bar {
            border-color: $smoke;
          }
        }
        td.column:nth-child(2) {
          visibility: hidden;
        }
      }
    }
  }
}

.ratings-dialog-over-time-graph {
  @include graph-colors($forest, $pine, $mint);
  .hover-info {
    @include hover-info-colors($forest, $pine, $mint);
  }
}

.apple-ratings-dialog-over-time-graph {
  @include graph-colors($lapis, $silver, $sky);
  .hover-info {
    @include hover-info-colors($lapis, $silver, $sky);
  }
}

.web-survey-response-graph {
  @include graph-colors($shadow);
  .hover-info {
    @include hover-info-colors($shadow);
  }
}

.active-users-daily-graph {
  @include graph-colors($orchid);
  .hover-info {
    @include hover-info-colors($orchid);
  }
}

.active-users-monthly-graph {
  @include graph-colors($eggplant);
  .hover-info {
    @include hover-info-colors($eggplant);
  }
}

.rating-and-review-count {
  .main-callout {
    .main-callout-title {
      h1, p {
        color: $royal;
      }
    }
    padding-top: 5px;
    padding-bottom: 40px;
    background-color: $snow;

    h3.callout-datum-2 {
      font-size: 12px;
      &::before {
        content: "of"
      }
    }
    .callout-row-1 {
      padding: 0 15px 0 15px;
      display: flex;
      justify-content: space-around;
      margin-top: 15px;
    }
  }
}


.average-rating-graph {
  .key {
    div:nth-child(1) {
      div.ios{
        background: $royal;
      }
    }
    div:nth-child(2) {
      div.ios{
        background: $shadow;
      }
    }
    div:nth-child(3) {
      div.ios{
        background: $smoke;
      }
    }
    div:nth-child(1) {
      div.android {
        background: $shadow;
      }
    }
    div:nth-child(2) {
      div.android {
        background: $smoke;
      }
    }
  }
  .graph-wrapper {
    svg {
      g.series {
        path:only-child {
          stroke: $shadow;
        }
        path:nth-child(1):nth-last-child(2) {
          stroke: $royal;
        }
        path:nth-child(2):nth-last-child(1) {
          stroke: $shadow;
        }
      }
      .y-axis-reference-lines {
        path {
          stroke: $smoke;
        }
      }
    }
    .tooltip {
      .hover-info {
        h3 {
          display: block;
        }
        .series-summaries {
          .series-summary:only-child {
            h5 {
              color: $shadow;
            }
          }
          .series-summary:nth-child(1):nth-last-child(2) {
            h5 {
              color: $royal;
            }
          }
          .series-summary:nth-child(2):nth-last-child(1) {
            h5 {
              color: $shadow;
            }
          }
        }
      }
    }
  }
}
