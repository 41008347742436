.popover {
  &__body {
    box-shadow: 0 2px 5px 0 rgba(#000, 0.26), 0 2px 10px 0 rgba(#000, 0.16);
    max-width: 300px;
    z-index: 10;
    background: #fff;
    color: #585858;
    font-weight: normal;
    padding: 1.5rem;
    border-radius: 3px;
    padding: 0;
  }
}
