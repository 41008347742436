@import './theme.scss';

.ratings-distribution {
  .hover-info {
    .series-summaries {
      .series-summary {
        &:nth-child(1) {
          .series-summary-label {
            color: $sky;
          }
        }
        &:nth-child(2) {
          .series-summary-label {
            color: $shadow;
          }
        }
        &:nth-child(3) {
          .series-summary-label {
            color: $smoke;
          }
        }
      }
    }
  }
}

.hover-info {
  box-shadow: 0 2px 5px 0 rgba($black, 0.26), 0 2px 10px 0 rgba($black, 0.16);
  padding: 1em;
  display: inline-block;
  @include hover-info-colors($pine, $nimbus);
  color: $onyx;
  background: $white;
  position: relative;
  z-index: 2;

  .info-on {
    font-size: 16px;
    font-weight: normal;
    margin: 0 0 1em;
    font-weight: $bold;
  }

  .breakdown {
    margin: 0;

    .summary {
      font-size: 14px;
      color: $onyx;
      font-weight: $regular;
    }
    .summaries {
      display: flex;
      justify-content: space-between;

      .summary {
        white-space: nowrap;

        &.info-for {
          margin-right: 10px;
        }
      }
    }

    .horizontal-bar {
      display: flex;
      width: 260px;
      height: 12px;
      margin-bottom: 20px;

      &:empty {
        display: none;
      }

      div {
        height: 12px;

        &:last-of-type {
          border-right: none;
        }
      }
    }
  }

  .series-summaries {
    display: flex;

    .series-summary {
      border-left: solid 1px $nimbus;
      padding: 0 0 0 9px;
      flex: 1 1 auto;

      margin-right: 20px;

      &:last-of-type {
        margin-right: 0;
      }

      .series-summary-label {
        font-size: 10px;
        font-weight: $bold;
        margin: 0px;
        text-transform: uppercase;
        white-space: nowrap;
      }

      .series-summary-value {
        font-size: 26px;
        line-height: 32px;
        font-weight: $regular;
        margin: -2px 0 0;
        color: $shadow;
      }

      .series-summary-detail {
        font-size: 14px;
        font-weight: $regular;
        margin: 0;
        color: $shadow;
      }
    }
  }
}

.hover-info.rating-distribution-hover {
  @include hover-info-colors($forest, $pine, $mango, $coral, $cayenne);
}
