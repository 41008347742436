@import '../../../styles/theme.scss';

.gdpr-status-item {
  &:hover {
    background: #edf3f9;
  }

  td {
    height: 60px;
  }

  &__table-status-container {
    display: flex;
    justify-content: flex-start;

    > i {
      height: 24px;
      width: 24px;
      align-items: center;
      margin: 0 5px 0 0;
      padding-bottom: 2px;

      svg {
        height: 24px;
        width: 24px;
      }
    }
  }

  &__actions-container {
    display: flex;
    justify-content: center;
    align-items: center;

    > i {
      height: 16px;
      width: 16px;
      cursor: pointer;

      > path {
        fill: #666666;
      }

      svg {
        height: 16px;
        width: 16px;
      }

      * {
        cursor: pointer;
      }
    }
  }

  &__dropdown-container {
    border: 1px solid #cdcdcd;
    min-width: 120px;
    margin-top: 10px;

    &-list > div {
      padding: 10px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 12px;

      i {
        margin-right: 6px;

        > svg {
          margin-right: 5px;
          height: 19px;
          width: 19px;
        }
      }
    }

    &-list__unabled-option {
      cursor: pointer;

      &:hover {
        background: #478fcc;
        color: #fff;

        > i svg path {
          fill: #fff;
        }
      }
    }

    &-list__disabled-option {
      opacity: 0.4;
      cursor: default;

      * {
        cursor: default;
      }
    }
  }
}

@media #{$mobile-media} {
  .gdpr-status-item {
    &__date,
    &__group,
    &__id,
    &__activity,
    &__creator {
      width: 0;
      position: absolute;
      opacity: 0;
    }
  }
}

@media #{$tablet-media} {
  .gdpr-status-item {
    &__group,
    &__id,
    &__creator {
      width: 0;
      position: absolute;
      opacity: 0;
    }
  }
}
