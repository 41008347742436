// Fonts
$primary-font: 'Lato', 'Lato-Regular', Tahoma, sans-serif;
$primary-font-bold: 'Lato-Bold', 'Lato', sans-serif;
$primary-font-color: #555;
$primary-font-px: 16px;
$primary-line-height: 1.4em;

// Official Colors
// TODO: Swap old colors out for these
$white: #fff;
$snow: #fafafa;
$nimbus: #e2e2e2;
$smoke: #b5b5b5;
$silver: #acacac;
$shadow: #575757;
$onyx: #333333;
$gray: #777777;
$light-gray: #D5D5D5;
$very-light-gray: #ECECEC;
$silver:  #C1C1C1;
$silver-light: #C5C5C5;
$grey: #D0D0D0;

$amethyst: #bb65ca;
$orchid: #9d1bb2;
$eggplant: #6b0f9c;
$indian-red: #C75959;

$malibu: #70b7ea;
$sky: #64b5f7;
$royal: #1194f6;
$lapis: #1565c0;
$turquoise-blue: #598EC7;

$mint: #80cac3;
$pine: #159688;
$forest: #0b695c;

$mango: #fba900;

$coral: #fd8980;
$chinook: #ea4755;
$cayenne: #de2230;
$mandy: #e84959;

// Old Colors (Don't Use!)
$black: black;
$near-black: #1d2022;
$mandy: #e84958;
$scorpion: #585858;
$dark-gray: #3a3e41;
$spring-wood: #f7f5f2;
$navy-blue: #263d4c;
$sherpa-blue: #0393be;

$darkest-red: #b7231e;
$darker-red: #cc3833;
$crimson: #db2637;

$darkest-green: #457a1a;
$darker-green: #5da423;

// Highlight Colors
$yellow-orange: #f3a536;

// Util Colors
$transparent: rgba(255, 255, 255, 0);

$primary-bg-color: $white;
$secondary-bg-color: $spring-wood;
$tertiary-bg-color: $navy-blue; // TODO: replace with actual style color later

$primary-font-color: $scorpion;
$secondary-font-color: $mandy;
$tertiary-font-color: $spring-wood;

$primary-highlight-color: $mandy;
$secondary-highlight-color: $sherpa-blue;

// Forms
$form-bg: $white;
$form-border: #a5afb5;
$form-box-shadow: #c4d3d9;
$form-green: #52b37f;
$form-primary: $sherpa-blue;
$form-shadow: #223447;
$form-disabled-text: #bec6d1;
$form-disabled-background: #d9dee4;

$label-em: #8a9299;
$label-error-background: #ffe5e5;
$label-error-red: #ff0a0a;
$label-success-border: #d6e9c6;
$label-success-background: #dff0d8;
$fieldset-white: #e5eaec;
$form-read-only: #8a9299;

$button-gray: #e7eaee;

// Onboarding
$onboarding-red: #ef424a;
$onboarding-background: #e5eaec;

// Font sizes
$light: 200;
$regular: 300;
$bold: 700;

// Breakpoints
$small-screen: 600px;
$med-screen: 950px;
$lrg-screen: 1250px;

// App Drawer
$app-drawer-background: #292d30;
$app-drawer-color: #8f9397;

// Media Queries from Dokidoki
$mobile-media: 'only screen and (max-width: #{$small-screen})';
$tablet-media: 'only screen and (max-width: #{$med-screen})';
$large-media: 'only screen and (max-width: #{$lrg-screen})';

$small-media: 'only screen and (min-width: #{$small-screen})';
$med-media: 'only screen and (min-width: #{$med-screen})';
$lrg-media: 'only screen and (min-width: #{$lrg-screen})';

// Mixins
@mixin hover-info-colors($colors...) {
  .breakdown {
    .horizontal-bar .series {
      @for $i from 1 through length($colors) {
        &.series-#{$i - 1}-color {
          background: nth($colors, $i);
        }
      }
    }
  }
  .series-summaries .series-summary .series-summary-label {
    @for $i from 1 through length($colors) {
      &.series-#{$i - 1}-color {
        // ensures a minumum lightness for label colors for readability
        color: change-color(
          nth($colors, $i),
          $lightness: min(lightness(nth($colors, $i)), 80%)
        );
      }
    }
  }
}
