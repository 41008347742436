.gdpr-modal {
  position: fixed;
  top: calc(50% - 125px);
  left: calc(50% - 250px);
  z-index: 100;
  width: 453px;
  background: #fff;
  border: 2px solid #5d5d5d;
  border-radius: 15px;

  &__body {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: bold;
    flex-direction: column;
    text-align: center;
    padding: 21px;

    &__title {
      font-size: 24px;
      font-weight: bold;
      padding-bottom: 10px;
    }

    &__text {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: normal;
      font-size: 18px;
      color: #5d5d5d;
      height: 54px;
    }

    &-input {
      margin-top: 10px;
      width: 50%;
      border: 1px solid #1e1e1e;
      padding: 5px;
      border-radius: 5px;
      font-size: 14px;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px 21px 21px;

    button {
      margin-left: 15px;
      border-radius: 6px;
      font-size: 16px;
      cursor: pointer;
      width: 138px;
      height: 40px;
    }

    button:disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }

    &-cancel {
      border: 1px solid #1e1e1e;
      color: #1e1e1e;
    }

    &-delete {
      background: #ff414d;
      border: 1px solid #ff414d;
      color: #fff;
    }
  }
}
